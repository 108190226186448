import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';

import '../styles/global.css';

export default ({ data }) => {
  return (
    <main>
      <Helmet>
        <title>aboutnils.com</title>
      </Helmet>
      <Header data={data} />
      <div class="content flex">
        <div class="left">
          <h1>Error 404 <span role="img" class="emoji" aria-label="waving hand">⛔</span></h1>
          <p class="subheadline">This page does not seem to exist.<br/></p>

        </div>
      </div>
    </main>
  );
}

export const query = graphql`
query {
  profilePic: file(relativePath: { eq: "nils.png" }) {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  site {
    siteMetadata {
      title
    }
  }
}
`